<template>
    <div class="Register">
        <el-card class="card">
            <h2>注册用户</h2>
            <el-form
                ref="form"
                :model="form"
                label-width="90px"
                align="left"
                @submit.native.prevent
                :rules="rules"
                size="small"
            >
                <el-form-item label="员工类型">
                    <el-radio-group v-model.number="form.type" @change="changeType">
                        <el-radio :label="1">业务员工</el-radio>
                        <el-radio :label="0">管理员</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="企业" prop="companyCode">
                    <el-select style="width: 330px" v-model="form.companyCode" @change="change">
                        <el-option v-for="c in meta.companies" :label="c.name" :value="c.code" :key="c.code" />
                    </el-select>
                </el-form-item>
                <el-form-item label="用户名" prop="username">
                    <el-input v-model.trim="form.username" />
                </el-form-item>
                <el-form-item label="密码" prop="password">
                    <el-input
                        placeholder="请输入6-20位密码,仅能输入大小写字母和数字"
                        type="password"
                        v-model.trim="form.password"
                        show-password
                    />
                </el-form-item>
                <el-form-item label="确认密码" prop="checkPass">
                    <el-input type="password" v-model.trim="form.checkPass" show-password />
                </el-form-item>
                <el-form-item label="手机号" prop="mobile">
                    <el-input v-model.trim="form.mobile" />
                </el-form-item>
                <el-form-item label="短信验证码" prop="verifyCode">
                    <el-col :span="11">
                        <el-input v-model.trim="form.verifyCode" />
                    </el-col>
                    <el-col class="line" :span="8" :offset="5">
                        <span v-show="!meta.verify.show" class="count">{{ meta.verify.count }} s</span>
                        <el-button v-show="meta.verify.show" style="width: 100%" @click="clickSendVerifyCode"
                            >发送验证码
                        </el-button>
                    </el-col>
                </el-form-item>
                <el-form-item label="姓名" prop="realName">
                    <el-input v-model.trim="form.realName" />
                </el-form-item>
                <el-form-item label="性别">
                    <el-radio-group v-model.number="form.sex">
                        <el-radio :label="0">男</el-radio>
                        <el-radio :label="1">女</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="年龄" prop="age">
                    <el-input v-model.number="form.age" />
                </el-form-item>
                <el-form-item label="身份证号" prop="idNumber">
                    <el-input v-model.trim="form.idNumber" />
                </el-form-item>
                <el-form-item label="机构类型">
                    <el-radio-group v-model.number="form.deptType">
                        <el-radio :label="3">门店</el-radio>
                        <el-radio :label="2">配送中心</el-radio>
                        <!--隐藏掉总部<el-radio :label="1">总部</el-radio>-->
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="省份" v-if="form.type">
                    <el-select style="width: 330px" v-model="form.provinceCode" @change="change">
                        <el-option v-for="p in meta.provinces" :label="p.name" :value="p.code" :key="p.code" />
                    </el-select>
                </el-form-item>
                <el-form-item label="所属机构" v-if="form.type">
                    <el-select style="width: 330px" v-model="form.deptCode">
                        <el-option v-for="d in meta.depts" :label="d.name" :value="d.code" :key="d.code" />
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-col :span="11">
                        <el-button type="primary" @click="clickReg('form')" style="width: 100%">注册</el-button>
                    </el-col>
                    <el-col :span="11" :offset="2">
                        <el-button type="reset" style="width: 100%" @click="login">取消</el-button>
                    </el-col>
                </el-form-item>
                <hr class="hr" />

                <a class="cursor" @click="login">已有账号,去登录</a>
            </el-form>
        </el-card>
    </div>
</template>

<script>
import UrlUtils from '../js/UrlUtils';
import qs from 'qs';

export default {
    name: 'Register',
    data() {
        const checkPass = (rule, value, callback) => {
            if (value !== this.form.password) {
                callback(new Error('两次输入密码不一致!'));
            } else {
                callback();
            }
        };
        return {
            form: {
                type: 1,
                companyCode: '',
                username: '',
                password: '',
                checkPass: '',
                mobile: '',
                verifyCode: '',
                realName: '',
                sex: 0,
                age: null,
                idNumber: '',
                deptType: 3,
                provinceCode: '',
                deptCode: '',
            },
            meta: {
                companies: [],
                provinces: [],
                depts: [],
                verify: {
                    show: true,
                    timer: null,
                    count: 60,
                },
            },
            rules: {
                companyCode: [{ required: true, trigger: ['blur', 'change'], message: '请选择企业' }],
                username: [
                    {
                        required: true,
                        trigger: 'blur',
                        message: '请正确输入用户名',
                        pattern: window.ef.someRegs.userName,
                    },
                ],
                password: [
                    { required: true, trigger: 'blur', message: '请正确输入密码', pattern: /^[0-9A-Za-z]{6,20}$/ },
                ],
                checkPass: [{ validator: checkPass, trigger: 'blur' }],
                mobile: [
                    {
                        required: true,
                        trigger: 'blur',
                        message: '请正确输入手机号',
                        pattern: window.ef.someRegs.mobile,
                    },
                ],
                realName: [
                    {
                        required: true,
                        trigger: 'blur',
                        message: '请正确输入姓名',
                        pattern: window.ef.someRegs.realName,
                    },
                ],
                age: [
                    { required: true, type: 'number', min: 18, max: 99, trigger: 'blur', message: '请输入正确的年龄' },
                ],
                idNumber: [
                    {
                        required: true,
                        trigger: 'blur',
                        message: '请正确输入身份证号',
                        pattern: /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/,
                    },
                ],
            },
        };
    },
    methods: {
        login() {
            this.$router.push({ name: 'login' });
        },
        changeType(type) {
            this.form.provinceCode = '';
            this.form.deptCode = '';
        },
        change() {
            UrlUtils.QueryRemote(
                this,
                '/regDepts?' +
                    qs.stringify({
                        type: this.form.deptType,
                        provinceCode: this.form.provinceCode,
                        companyCode: this.form.companyCode,
                    }),
                (data) => {
                    this.meta.depts = data;
                }
            );
        },
        clickReg(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return true;
                }
                UrlUtils.PostRemote(this, '/register', this.form, null, (data) => {
                    this.$message.success('注册成功,请登录');
                    this.goBack();
                });
            });
        },

        clickSendVerifyCode() {
            //verifyCode?mobile=15910859143&companyCode=TDSCPY15663520595212
            const vm = this.meta.verify;

            function countDown() {
                const TIME_COUNT = 60;
                if (!vm.timer) {
                    vm.count = TIME_COUNT;
                    vm.show = false;
                    vm.timer = setInterval(function () {
                        if (vm.count > 0 && vm.count <= TIME_COUNT) {
                            vm.count--;
                        } else {
                            vm.show = true;
                            clearInterval(vm.timer);
                            vm.timer = null;
                        }
                    }, 1000);
                }
            }

            if (!this.form.companyCode) {
                this.$message.error('请选择企业');
                return;
            }
            if (!this.form.mobile || !window.ef.someRegs.mobile.test(this.form.mobile)) {
                this.$message.error('请填写手机号');
                return;
            }
            UrlUtils.QueryRemote(
                this,
                '/verifyCode?' +
                    qs.stringify({
                        mobile: this.form.mobile,
                        companyCode: this.form.companyCode,
                    }),
                (data) => {
                    countDown();
                },
                (error) => {
                    this.$message.error(error);
                }
            );
        },
    },
    mounted() {
        UrlUtils.QueryRemote(this, '/system/company/list', (data) => {
            this.meta.companies = data;
        });
        UrlUtils.QueryRemote(this, '/system/area/list?level=1', (data) => {
            this.meta.provinces = data;
        });
        UrlUtils.QueryRemote(
            this,
            '/regDepts?' +
                qs.stringify({
                    type: this.form.deptType,
                    provinceCode: this.form.provinceCode,
                    companyCode: this.form.companyCode,
                }),
            (data) => {
                this.meta.depts = data;
            }
        );
    },
};
</script>

<style scoped>
.Register {
    background-image: url('../assets/login/bg.png');
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    background-size: cover;
    overflow-y: auto;
}

.Register .card {
    width: 460px;
    margin: 0 auto;
    margin-top: 10%;
}

.Register .cursor {
    font-size: 14px;
    color: #19c989;
    cursor: pointer;
}
</style>
